import React from "react";
import { motion } from "framer-motion";
import PageTemplate from "./PageTemplate";
import "./About.css";

const teamMembers = [
  {
    name: "Chase Francl",
    role: "COO & Provider Relations",
    image: "/images/chase.png",
    bio: "20+ years in behavior health, previous founder, CEO of Mid-Plains Center. ",
  },
  {
    name: "Jeff Stafford",
    role: "CFO & Business Development",
    image: "/images/jeff.png",
    bio: "30+ years in business leadership, former CEO - UHC NE, investment and commercial banking.",
  },
  {
    name: "Jon Rhoades",
    role: "CEO & Technology",
    image: "/images/jon.png",
    bio: "20+ years in technology, previous founder, software engineer",
  },
];

const values = [
  {
    title: "Innovation",
    icon: "fas fa-lightbulb",
    description: "Pushing boundaries in healthcare technology",
  },
  {
    title: "Security",
    icon: "fas fa-shield-alt",
    description: "Protecting patient data with cutting-edge security",
  },
  {
    title: "Accessibility",
    icon: "fas fa-universal-access",
    description: "Making healthcare available to everyone",
  },
];

const About = () => {
  return (
    <PageTemplate
      title="About Login.health"
      subtitle="Transforming healthcare access through innovative technology"
    >
      <section className="our-story">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <h2>Our Story</h2>
          <p style={{ fontWeight: "bold" }}>
            Simplifying Healthcare, One Login at a Time
          </p>

          <p>
            At Login.health, we believe accessing your healthcare should be as
            seamless as unlocking your phone. Our journey began with a simple
            yet transformative idea: to eliminate the stress and confusion of
            managing healthcare information across multiple platforms.
          </p>
          <p>
            Our founders were inspired by their own personal experiences
            navigating the healthcare system—lost passwords, endless forms, and
            scattered records. They knew there had to be a better way. With a
            vision to create a unified, patient-first solution, Login.health was
            born.
          </p>
          <p>
            We set out to solve a major pain point: enabling patients to check
            in instantly, securely, and universally. With Login.health, you
            don’t need multiple passwords or redundant paperwork. Our platform
            connects your healthcare apps with a single, secure login, ensuring
            accurate, verified information every time.
          </p>
          <p>
            But our mission goes beyond convenience. We aim to empower patients,
            simplify communication, and build trust between providers and their
            communities. Healthcare should be about your well-being, not endless
            administrative tasks.
          </p>
          <p>
            Today, Login.health is more than a tool—it’s a movement toward a
            more connected, efficient, and patient-centered healthcare
            experience. Whether you’re a busy parent, a professional managing
            chronic care, or simply someone who values time and security, we’re
            here to help you take control of your health records.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Join us. Let’s redefine healthcare access, one login at a time.
          </p>
        </motion.div>
      </section>

      <section className="our-team">
        <h2>Our Team</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <motion.div
              key={member.name}
              className="team-member"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
            >
              <div className="member-image">
                <img src={member.image} alt={member.name} />
              </div>
              <h3>{member.name}</h3>
              <h4>{member.role}</h4>
              <p>{member.bio}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="our-values">
        <h2>Our Values</h2>
        <div className="values-grid">
          {values.map((value, index) => (
            <motion.div
              key={value.title}
              className="value-badge"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
            >
              <i className={value.icon}></i>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </motion.div>
          ))}
        </div>
      </section>
    </PageTemplate>
  );
};

export default About;
