import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import PageTemplate from "./PageTemplate";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { requestCode, verifyCode, isLoading } = useAuth();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await requestCode(email);
      setShowCode(true);
    } catch (err) {
      setError("Failed to send access code. Please try again.");
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await verifyCode(email, code);
      const from = location.state?.from?.pathname || "/dataroom";
      navigate(from);
    } catch (err) {
      setError("Invalid access code. Please try again.");
    }
  };

  return (
    <PageTemplate
      title="Login"
      subtitle="Access your secure investor dashboard"
      showHeader={false}
    >
      <div className="login-container">
        <div className="login-card">
          {error && <div className="error-message">{error}</div>}
          {!showCode ? (
            <form onSubmit={handleEmailSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="login-button"
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Get Access Code"}
              </button>
            </form>
          ) : (
            <form onSubmit={handleCodeSubmit}>
              <div className="form-group">
                <label htmlFor="code">Enter Access Code from Email</label>
                <input
                  type="text"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                  autoComplete="one-time-code"
                />
              </div>
              <button
                type="submit"
                className="login-button"
                disabled={isLoading}
              >
                {isLoading ? "Verifying..." : "Login"}
              </button>
              <button
                type="button"
                className="back-button"
                onClick={() => {
                  setShowCode(false);
                  setCode("");
                  setError("");
                }}
              >
                Back to Email
              </button>
            </form>
          )}
        </div>
      </div>
    </PageTemplate>
  );
};

export default Login;
