import React, { useState } from "react";
import { motion } from "framer-motion";
import PageTemplate from "./PageTemplate";
import "./WaitingList.css";
import { trackEvent } from "../services/analytics";

const WaitingList = () => {
  const [formData, setFormData] = useState({
    email: "",
    role: "patient", // or "provider"
    interest: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    trackEvent("Waitlist", "Submit", formData.role);
    try {
      const response = await fetch(
        "https://api.login.health/website/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            reason:
              "WAITLIST SIGNUP\n" +
              "\nRole: " +
              formData.role +
              "\n\nInterest:" +
              formData.interest,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to send to Slack");

      alert("Thank you for joining our waitlist! We will be in touch soon.");
      setFormData({
        email: "",
        role: "patient",
        interest: "",
      });
    } catch (error) {
      console.error("Error submitting to waitlist:", error);
      alert(
        "There was an error submitting your request. Please try again later."
      );
    }
  };

  return (
    <PageTemplate
      title="Join the Waitlist"
      subtitle="Be the first to know when Login.health launches"
    >
      <motion.section
        className="waitlist-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <div className="waitlist-container">
          <form onSubmit={handleSubmit} className="waitlist-form">
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="role">I am a</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="patient">Patient</option>
                <option value="provider">Healthcare Provider</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="interest">
                What interests you most about Login.health?
              </label>
              <textarea
                id="interest"
                name="interest"
                value={formData.interest}
                onChange={handleChange}
                rows="4"
                placeholder="Tell us what excites you about Login.health..."
              ></textarea>
            </div>

            <button type="submit" className="waitlist-button">
              Join Waitlist
            </button>
          </form>

          <div className="waitlist-info">
            <h3>Why Join Our Waitlist?</h3>
            <ul>
              <li>
                <i className="fas fa-check-circle"></i>
                Early Access to Platform
              </li>
              <li>
                <i className="fas fa-gift"></i>
                Exclusive Launch Benefits
              </li>
              <li>
                <i className="fas fa-star"></i>
                Priority Support
              </li>
              <li>
                <i className="fas fa-bell"></i>
                Launch Updates
              </li>
              <li>
                <i className="fas fa-users"></i>
                Join Our Community
              </li>
            </ul>

            <div className="waitlist-stats">
              <div className="stat">
                <span className="stat-number">500+</span>
                <span className="stat-label">Waiting</span>
              </div>
              <div className="stat">
                <span className="stat-number">Q2</span>
                <span className="stat-label">Expected Launch</span>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </PageTemplate>
  );
};

export default WaitingList;
