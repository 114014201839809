import React from "react";
import { motion } from "framer-motion";
import { trackEvent } from "../services/analytics";
import PageTemplate from "./PageTemplate";
import "./Features.css";

const Features = () => {
  return (
    <PageTemplate
      title="More Control = Better Care"
      subtitle="Discover how Login.health is revolutionizing your health records"
    >
      <motion.section
        className="instant-checkin-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Instant Check-In: Streamlined Healthcare Access</h2>
        <div className="instant-checkin-grid">
          <div className="instant-checkin-item">
            <i className="fas fa-qrcode"></i>
            <h3>Digital Check-In</h3>
            <p>
              Skip the paperwork with our digital check-in system. Complete all
              necessary forms and verifications before your appointment through
              our secure platform.
            </p>
          </div>

          <div className="instant-checkin-item">
            <i className="fas fa-id-card"></i>
            <h3>Insurance Verification</h3>
            <p>
              Seamlessly verify your insurance coverage and benefits.
              Pre-validate your insurance information to avoid delays and
              billing surprises.
            </p>
          </div>

          <div className="instant-checkin-item">
            <i className="fas fa-clock"></i>
            <h3>Wait Time Management</h3>
            <p>
              Get real-time updates on wait times and queue status. Receive
              notifications when it's your turn, allowing you to arrive just in
              time.
            </p>
          </div>

          <div className="instant-checkin-item">
            <i className="fas fa-file-medical-alt"></i>
            <h3>Pre-Visit Forms</h3>
            <p>
              Complete medical history updates and consent forms in advance.
              Update your information anytime, anywhere, ensuring your records
              are always current.
            </p>
          </div>
        </div>

        <div className="instant-checkin-features">
          <div className="feature-list">
            <h3>Key Features</h3>
            <ul>
              <li>Contactless digital check-in process</li>
              <li>Real-time insurance verification</li>
              <li>Smart queue management system</li>
              <li>Automated appointment reminders</li>
              <li>Secure document submission</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="unified-identity-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Unified Identity: One Login for All Your Healthcare</h2>
        <div className="unified-identity-grid">
          <div className="unified-identity-item">
            <i className="fas fa-fingerprint"></i>
            <h3>Single Sign-On</h3>
            <p>
              Access all your healthcare providers with one secure login. No
              more juggling multiple passwords or accounts for different medical
              facilities.
            </p>
          </div>

          <div className="unified-identity-item">
            <i className="fas fa-key"></i>
            <h3>Credential Management</h3>
            <p>
              Securely store and manage your healthcare credentials.
              Automatically sync your identity across your approved healthcare
              network.
            </p>
          </div>

          <div className="unified-identity-item">
            <i className="fas fa-hospital-user"></i>
            <h3>Provider Integration</h3>
            <p>
              Seamlessly connect with new healthcare providers. Your verified
              identity travels with you across our network of partner
              facilities.
            </p>
          </div>

          <div className="unified-identity-item">
            <i className="fas fa-user-shield"></i>
            <h3>Identity Verification</h3>
            <p>
              Multi-factor identity verification ensures you're always in
              control. Easily prove your identity when accessing new healthcare
              services.
            </p>
          </div>
        </div>

        <div className="unified-identity-features">
          <div className="feature-list">
            <h3>Key Benefits</h3>
            <ul>
              <li>One secure login for all healthcare services</li>
              <li>Automated provider credential sharing</li>
              <li>Biometric authentication support</li>
              <li>Cross-platform identity verification</li>
              <li>Instant access to new healthcare providers</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="secure-auth-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Secure Authentication: Enterprise-Grade Protection</h2>
        <div className="secure-auth-grid">
          <div className="secure-auth-item">
            <i className="fas fa-shield-alt"></i>
            <h3>Multi-Factor Authentication</h3>
            <p>
              Choose from multiple authentication methods including biometrics,
              authenticator apps, and secure tokens. Layer your security to
              match your needs.
            </p>
          </div>

          <div className="secure-auth-item">
            <i className="fas fa-lock"></i>
            <h3>Advanced Encryption</h3>
            <p>
              Industry-leading encryption protects your identity and data. All
              authentication processes use end-to-end encryption and secure
              protocols.
            </p>
          </div>

          <div className="secure-auth-item">
            <i className="fas fa-history"></i>
            <h3>Access Monitoring</h3>
            <p>
              Track all authentication attempts and access patterns. Receive
              instant alerts for any suspicious login activities or unauthorized
              attempts.
            </p>
          </div>

          <div className="secure-auth-item">
            <i className="fas fa-mobile-alt"></i>
            <h3>Device Management</h3>
            <p>
              Manage trusted devices and revoke access instantly. Keep track of
              which devices have access to your healthcare information.
            </p>
          </div>
        </div>

        <div className="secure-auth-features">
          <div className="feature-list">
            <h3>Security Features</h3>
            <ul>
              <li>HIPAA-compliant authentication protocols</li>
              <li>Real-time security monitoring</li>
              <li>Secure password recovery process</li>
              <li>Automated security updates</li>
              <li>Geographic access controls</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="unified-records-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Unified Records: Your Complete Health Story</h2>
        <div className="unified-records-grid">
          <div className="unified-records-item">
            <i className="fas fa-file-medical"></i>
            <h3>Comprehensive History</h3>
            <p>
              Access your complete medical history in one place. View past
              appointments, diagnoses, treatments, and lab results with ease.
            </p>
          </div>

          <div className="unified-records-item">
            <i className="fas fa-pills"></i>
            <h3>Medication Tracking</h3>
            <p>
              Keep track of all your medications, past and present. Monitor
              dosages, schedules, and potential interactions automatically.
            </p>
          </div>

          <div className="unified-records-item">
            <i className="fas fa-notes-medical"></i>
            <h3>Document Management</h3>
            <p>
              Store and organize all your medical documents digitally. From
              imaging results to vaccination records, everything is securely
              archived.
            </p>
          </div>

          <div className="unified-records-item">
            <i className="fas fa-chart-line"></i>
            <h3>Health Analytics</h3>
            <p>
              Visualize your health trends over time. Track vital statistics,
              test results, and health metrics with interactive charts.
            </p>
          </div>
        </div>

        <div className="unified-records-features">
          <div className="feature-list">
            <h3>Key Features</h3>
            <ul>
              <li>Automated record organization and categorization</li>
              <li>Smart search across all medical documents</li>
              <li>Custom health tracking dashboards</li>
              <li>Integrated provider records sync</li>
              <li>Comprehensive medical timeline view</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="care-circle-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Care Circle: Your Connected Health Network</h2>
        <div className="care-circle-grid">
          <div className="care-circle-item">
            <i className="fas fa-user-shield"></i>
            <h3>Family Access</h3>
            <p>
              Grant secure access to family members who help manage your
              healthcare. Set custom permission levels for different family
              members.
            </p>
          </div>

          <div className="care-circle-item">
            <i className="fas fa-user-md"></i>
            <h3>Provider Collaboration</h3>
            <p>
              Connect with your healthcare providers seamlessly. Share records,
              receive updates, and maintain continuous communication.
            </p>
          </div>

          <div className="care-circle-item">
            <i className="fas fa-clock"></i>
            <h3>Temporary Access</h3>
            <p>
              Create time-limited access for specialists or temporary
              caregivers. Perfect for consultations or short-term care
              situations.
            </p>
          </div>

          <div className="care-circle-item">
            <i className="fas fa-ambulance"></i>
            <h3>Emergency Access</h3>
            <p>
              Set up emergency contacts who can access critical health
              information when needed. Ensure your care team has vital
              information in urgent situations.
            </p>
          </div>
        </div>

        <div className="care-circle-features">
          <div className="feature-list">
            <h3>Key Benefits</h3>
            <ul>
              <li>Real-time notifications when records are accessed</li>
              <li>Detailed access logs for security and transparency</li>
              <li>
                Customizable sharing permissions for different types of records
              </li>
              <li>Secure messaging system between care circle members</li>
              <li>Easy addition and removal of care circle members</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="family-history-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Family Health History: Understanding Your Health Heritage</h2>
        <div className="family-history-grid">
          <div className="family-history-item">
            <i className="fas fa-dna"></i>
            <h3>Health Tree Mapping</h3>
            <p>
              Build an interactive family health tree that maps medical
              conditions, treatments, and outcomes across generations. Identify
              patterns and potential hereditary risks.
            </p>
          </div>

          <div className="family-history-item">
            <i className="fas fa-chart-line"></i>
            <h3>Risk Assessment</h3>
            <p>
              Receive personalized risk assessments based on your family health
              patterns. Get proactive recommendations for preventive care and
              early screening.
            </p>
          </div>

          <div className="family-history-item">
            <i className="fas fa-users"></i>
            <h3>Multi-Generation Insights</h3>
            <p>
              Connect health records across family members to build a
              comprehensive view of inherited conditions. Track health patterns
              through multiple generations.
            </p>
          </div>

          <div className="family-history-item">
            <i className="fas fa-clipboard-list"></i>
            <h3>Health Timeline</h3>
            <p>
              Create detailed timelines of family health events. Document age of
              onset, treatments, and outcomes to help inform healthcare
              decisions.
            </p>
          </div>
        </div>

        <div className="family-history-features">
          <div className="feature-list">
            <h3>Key Features</h3>
            <ul>
              <li>Interactive family health tree visualization</li>
              <li>Automated inheritance pattern detection</li>
              <li>Customizable health condition tracking</li>
              <li>Secure family member data sharing</li>
              <li>Genetic counseling preparation tools</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="control-benefits-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h2>Why More Control Leads to Better Healthcare</h2>
        <div className="control-benefits-grid">
          <div className="control-benefits-item">
            <i className="fas fa-brain"></i>
            <h3>Informed Decision Making</h3>
            <p>
              When you have complete access to your health information, you can
              make more informed decisions about your care. Understanding your
              full medical history, treatment options, and health trends
              empowers you to actively participate in your healthcare journey.
            </p>
          </div>

          <div className="control-benefits-item">
            <i className="fas fa-chart-line"></i>
            <h3>Proactive Health Management</h3>
            <p>
              Access to comprehensive health data allows you to spot patterns
              and take preventive action. By monitoring your health metrics and
              understanding your risk factors, you can make lifestyle changes
              and seek early intervention when needed.
            </p>
          </div>

          <div className="control-benefits-item">
            <i className="fas fa-comments"></i>
            <h3>Better Provider Communication</h3>
            <p>
              Having your complete health history at your fingertips leads to
              more productive conversations with healthcare providers. You can
              share accurate information, ask informed questions, and ensure all
              your providers are aligned with your care plan.
            </p>
          </div>

          <div className="control-benefits-item">
            <i className="fas fa-user-md"></i>
            <h3>Personalized Care Plans</h3>
            <p>
              When providers have access to your complete health story, they can
              create more effective, personalized treatment plans. Your
              comprehensive health record helps them understand your unique
              needs and make better- informed clinical decisions.
            </p>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="features-cta"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        <h2>Ready to transform your healthcare experience?</h2>
        <button
          onClick={() => {
            trackEvent('Features', 'Click', 'Get Started CTA');
            window.location.href = "/waitlist";
          }}
          className="cta-button"
        >
          Get Started Now
        </button>
      </motion.section>
    </PageTemplate>
  );
};

export default Features;
