import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PageTemplate from "./PageTemplate";
import "./Contact.css";

const FAQs = [
  {
    question: "How secure is my health data?",
    answer:
      "We employ industry-leading encryption and follow HIPAA compliance guidelines to ensure your health data remains private and secure.",
  },
  {
    question: "What support options are available?",
    answer:
      "We offer 24/7 support through live chat, email, and phone. Our dedicated team is always ready to assist you.",
  },
  {
    question: "How do I get started with Login.health?",
    answer:
      "Simply create an account, complete your profile, and you can immediately begin accessing our healthcare services.",
  },
];

const Contact = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    inquiryType: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://api.login.health/website/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            reason:
              "CONTACT FORM SUBMISSION\n" +
              "\nInquiry Type: " +
              formData.inquiryType +
              "\nMessage: " +
              formData.message,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to send to Slack");

      alert("Thank you for reaching out! We will be in touch soon.");
      setFormData({
        email: "",
        role: "patient",
        interest: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(
        "There was an error submitting your request. Please try again later."
      );
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <PageTemplate
      title="Get in Touch"
      subtitle="Have questions? We're here to help!"
    >
      <div className="contact-container">
        <section className="contact-form-section">
          <motion.form
            className="contact-form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            onSubmit={handleSubmit}
          >
            <h2>Send us a Message</h2>

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="inquiryType">Type of Inquiry</label>
              <select
                id="inquiryType"
                name="inquiryType"
                value={formData.inquiryType}
                onChange={handleChange}
                required
              >
                <option value="">Select an option</option>
                <option value="general">General Inquiry</option>
                <option value="technical">Technical Support</option>
                <option value="billing">Billing Question</option>
                <option value="partnership">Partnership Opportunity</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
              ></textarea>
            </div>

            <button type="submit" className="submit-button">
              Send Message
            </button>
          </motion.form>
        </section>

        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-list">
            {FAQs.map((faq, index) => (
              <motion.div
                key={index}
                className="faq-item"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <button
                  className="faq-question"
                  onClick={() =>
                    setActiveIndex(activeIndex === index ? null : index)
                  }
                >
                  {faq.question}
                  <i
                    className={`fas fa-chevron-${
                      activeIndex === index ? "up" : "down"
                    }`}
                  ></i>
                </button>
                <AnimatePresence>
                  {activeIndex === index && (
                    <motion.div
                      className="faq-answer"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <p>{faq.answer}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </PageTemplate>
  );
};

export default Contact;
