import React, { useEffect, useState } from "react";
import PageTemplate from "./PageTemplate";

import "./DataRoom.css";
import { auth } from "../services/api";

const DataRoom = () => {
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/login";
    }
    const p = await auth.getProfile();

    if (p === "unauthorized") {
      window.location.href = "/login";
      return;
    }

    const u = await auth.getUser();
    // const a = await auth.getApp();
    // console.log("App", a);
    const d = await auth.getDataroom();
    console.log("Dataroom", d);
    setUser(u);
    setProfile(p.profile);
  }

  return (
    <div className="dataroom-page">
      <section className="dataroom-hero">
        <div className="hero-content">
          <h1>Dataroom</h1>
          <p>
            Access exclusive investor information and updates about Login.health
          </p>
          <div className="hero-stats">
            <div className="stat">
              <span className="stat-number">500+</span>
              <span className="stat-label">Healthcare Providers</span>
            </div>
            <div className="stat">
              <span className="stat-number">$3.9B</span>
              <span className="stat-label">Market Opportunity</span>
            </div>
            <div className="stat">
              <span className="stat-number">250%</span>
              <span className="stat-label">YoY Growth</span>
            </div>
          </div>
        </div>
        <div className="animated-background">
          {[...Array(10)].map((_, i) => (
            <i
              key={i}
              className={`fas fa-${
                [
                  "chart-line",
                  "dollar-sign",
                  "percentage",
                  "chart-pie",
                  "chart-bar",
                ][i % 5]
              } finance-symbol floating-icon`}
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                fontSize: `${Math.random() * 2 + 1}rem`,
                animationDelay: `${Math.random() * 2}s`,
              }}
            />
          ))}
        </div>
      </section>
      <div className="investor-menu">
        <div className="menu-logo">
          <img src="/loginhealth-3.png" alt="Login.health Logo" />
        </div>
        <div className="menu-subtitle">Investor Portal</div>
        <a href="#overview" className="menu-item">
          Overview
        </a>
        <a href="#team" className="menu-item">
          Team
        </a>
        <a href="#advisors" className="menu-item">
          Advisors
        </a>
        <a href="#metrics" className="menu-item">
          Metrics
        </a>
        <a href="#documents" className="menu-item">
          Documents
        </a>
        <a href="#updates" className="menu-item">
          Updates
        </a>
      </div>
      <div className="dataroom-container">
        <div className="split-section">
          <div className="problem-section">
            <h2>The Problem</h2>
            <div className="problem-content">
              <div className="problem-highlight">
                <i className="fas fa-exclamation-circle"></i>
                <p>
                  The healthcare industry faces significant challenges in
                  managing and accessing patient records effectively.
                </p>
              </div>
              <div className="problem-description">
                <p>
                  With <span className="stat">80%</span> of medical errors
                  stemming from miscommunication during care transitions and{" "}
                  <span className="stat">68%</span> of patients seeing multiple
                  providers, the lack of a unified record system creates
                  dangerous fragmentation.
                </p>
                <p>
                  The administrative burden is staggering - healthcare
                  organizations spend <span className="stat">$372 billion</span>{" "}
                  annually on administration, with providers dedicating{" "}
                  <span className="stat">45%</span> of their time to paperwork
                  and patients facing <span className="stat">30-minute</span>{" "}
                  average intake times.
                </p>
                <p>
                  Access to medical records remains a critical issue, as{" "}
                  <span className="stat">72%</span> of patients struggle to
                  obtain their records, facing{" "}
                  <span className="stat">2-3 week</span> wait times for requests
                  due to limited interoperability between systems. These
                  challenges not only impact patient care quality but also drive
                  up healthcare costs and reduce provider efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="pitch-deck-section">
            <h2>Investor Deck</h2>
            <div className="pitch-deck-container">
              <iframe
                title="Login.health Pitch Deck"
                src="https://www.canva.com/design/DAGR4sJPh8A/VdCIJDeqWLHamebQW9Tk_Q/view?embed"
                allowFullScreen
                frameBorder="0"
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </div>
          </div>
        </div>
        <div id="overview" className="company-overview-section">
          <h2>Company Overview</h2>
          <div className="overview-grid">
            <div className="overview-item">
              <i className="fas fa-building"></i>
              <h3>Formation</h3>
              <p>
                Founded in March 2023
                <br />
                Delaware C-Corporation
                <br />
                Based in Aurora, NE
              </p>
            </div>
            <div className="overview-item">
              <i className="fas fa-chart-pie"></i>
              <h3>Market Opportunity</h3>
              <p>
                TAM: 330M records
                <br />
                Potential ARR: $3.9B
                <br />
                Current Market: $1.2B
              </p>
            </div>
            <div className="overview-item">
              <i className="fas fa-trophy"></i>
              <h3>Key Achievements</h3>
              <p>
                Patent-Pending Technology
                <br />
                HIPAA Compliant
                <br />
                Strategic Partnerships
              </p>
            </div>
          </div>
        </div>

        <div id="team" className="team-section">
          <h2>Leadership Team</h2>
          <div className="team-grid" style={{ gap: "3rem" }}>
            <div className="team-member">
              <div className="member-image">
                <img src="/images/chase.png" alt="Chase Francl" />
              </div>
              <h3>Chase Francl</h3>
              <h4>COO & Provider Relations</h4>
              <p>
                20+ years in behavior health, previous founder, CEO of
                Mid-Plains Center.
              </p>
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/chase-francl"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn Profile"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.gallup.com/cliftonstrengths/en/253868/bp10-profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Gallup Strengths Profile"
                >
                  <i className="fas fa-chart-line"></i>
                </a>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image">
                <img src="/images/jeff.png" alt="Jeff Stafford" />
              </div>
              <h3>Jeff Stafford</h3>
              <h4>CFO & Business Development</h4>
              <p>
                30+ years in business leadership, former CEO - UHC NE,
                investment and commercial banking.
              </p>
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/jeff-stafford"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn Profile"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.gallup.com/cliftonstrengths/en/253868/bp10-profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Gallup Strengths Profile"
                >
                  <i className="fas fa-chart-line"></i>
                </a>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image">
                <img src="/images/jon.png" alt="Jon Rhoades" />
              </div>
              <h3>Jon Rhoades</h3>
              <h4>CEO & Technology</h4>
              <p>
                20+ years in technology, previous founder, software engineer
              </p>
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/jonrhoades"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn Profile"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.gallup.com/cliftonstrengths/en/253868/bp10-profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Gallup Strengths Profile"
                >
                  <i className="fas fa-chart-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="advisors" className="advisors-section">
          <h2>Board of Advisors</h2>
          <div className="advisors-grid">
            <div className="advisor-item">
              <div className="member-image">
                <img src="/images/sparks.png" alt="Kevin Sparks" />
              </div>
              <div className="advisor-info">
                <h3>Kevin Sparks</h3>
                <p>
                  Former President & CEO, UnitedHealthcare Community Plan of KS
                </p>
              </div>
            </div>
            <div className="advisor-item">
              <div className="member-image">
                <img src="/images/watson.png" alt="Nate Watson" />
              </div>
              <div className="advisor-info">
                <h3>Nate Watson</h3>
                <p>
                  Director of Compliance and Risk Management, Trivium Life
                  Services
                </p>
              </div>
            </div>
            <div className="advisor-item">
              <div className="member-image">
                <img src="/images/bagley.png" alt="Kevin Bagley" />
              </div>
              <div className="advisor-info">
                <h3>Kevin Bagley</h3>
                <p>Former Medicaid Director, LTSS Director</p>
              </div>
            </div>
            <div className="advisor-item">
              <div className="member-image">
                <img src="/images/krug.png" alt="Dr. Krug" />
              </div>
              <div className="advisor-info">
                <h3>Dr. Nate Krug</h3>
                <p>Twin Rivers Sports & Family Medicine</p>
              </div>
            </div>
          </div>
        </div>

        <div id="metrics" className="metrics-section">
          <h2>Key Metrics</h2>
          <div className="metrics-grid">
            <div className="metric-item">
              <i className="fas fa-hospital-user"></i>
              <div className="metric-value">500+</div>
              <p>Healthcare Providers</p>
            </div>
            <div className="metric-item">
              <i className="fas fa-user-shield"></i>
              <div className="metric-value">100K+</div>
              <p>Active Patients</p>
            </div>
            <div className="metric-item">
              <i className="fas fa-chart-bar"></i>
              <div className="metric-value">250%</div>
              <p>YoY Growth</p>
            </div>
            <div className="metric-item">
              <i className="fas fa-file-medical"></i>
              <div className="metric-value">1M+</div>
              <p>Records Processed</p>
            </div>
          </div>
        </div>

        <div id="documents" className="investor-documents">
          <h2>Investor Documents</h2>
          <div className="document-grid">
            <div className="document-item">
              <i className="fas fa-file-pdf"></i>
              <h3>Financial Model</h3>
              <p>Detailed projections and unit economics</p>
              <button className="download-button">Download</button>
            </div>
            <div className="document-item">
              <i className="fas fa-file-contract"></i>
              <h3>Term Sheet</h3>
              <p>Current investment round terms</p>
              <button className="download-button">Download</button>
            </div>
            <div className="document-item">
              <i className="fas fa-file-signature"></i>
              <h3>Due Diligence</h3>
              <p>Corporate and legal documentation</p>
              <button className="download-button">Download</button>
            </div>
          </div>
        </div>

        <div id="updates" className="updates-section">
          <h2>Investor Updates</h2>
          <div className="updates-grid">
            <div className="update-item">
              <div className="update-date">December 2023</div>
              <h3>Series A Progress Update</h3>
              <div className="update-highlights">
                <div className="highlight">
                  <i className="fas fa-chart-line"></i>
                  <span>Revenue grew 27% MoM</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-user-plus"></i>
                  <span>Added 3 key team members</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-handshake"></i>
                  <span>2 new strategic partnerships</span>
                </div>
              </div>
              <p>
                This month marked significant progress in our Series A
                fundraising efforts, with strong interest from
                healthcare-focused VCs. We've expanded our team with strategic
                hires in engineering and sales, while maintaining healthy cash
                reserves.
              </p>
              <button className="download-button">Read Full Update</button>
            </div>

            <div className="update-item">
              <div className="update-date">November 2023</div>
              <h3>Product Launch & Market Response</h3>
              <div className="update-highlights">
                <div className="highlight">
                  <i className="fas fa-rocket"></i>
                  <span>Beta launch completed</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-hospital"></i>
                  <span>5 new clinic partnerships</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-star"></i>
                  <span>98% user satisfaction</span>
                </div>
              </div>
              <p>
                Successfully launched our beta program with selected healthcare
                providers, receiving overwhelmingly positive feedback. Early
                metrics show strong user engagement and retention, validating
                our core value proposition.
              </p>
              <button className="download-button">Read Full Update</button>
            </div>

            <div className="update-item">
              <div className="update-date">October 2023</div>
              <h3>Technical Milestones & Compliance</h3>
              <div className="update-highlights">
                <div className="highlight">
                  <i className="fas fa-shield-alt"></i>
                  <span>HIPAA certification</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-code-branch"></i>
                  <span>API v2.0 released</span>
                </div>
                <div className="highlight">
                  <i className="fas fa-server"></i>
                  <span>99.99% uptime achieved</span>
                </div>
              </div>
              <p>
                Completed major technical milestones including HIPAA compliance
                certification and the release of our enhanced API.
                Infrastructure improvements have resulted in exceptional
                platform stability.
              </p>
              <button className="download-button">Read Full Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRoom;
