export const teamMembers = {
  jon: {
    name: "Jon Rhoades",
    role: "CEO & Technology",
    title: "CEO & Technology",
    image: "/images/jon.png",
    bio: "20+ years in technology, previous founder, software engineer",
    linkedin: "https://www.linkedin.com/in/jon-rhoades/",
    email: "jon@valid.care",
    phone: "(402) 410-1380",
    office: "Aurora, NE",
    url: "/team/jon",
  },
  jeff: {
    name: "Jeff Stafford",
    role: "CFO & Business Development",
    title: "Chief Financial Officer",
    image: "/images/jeff.png",
    bio: "30+ years in business leadership, former CEO - UHC NE, investment and commercial banking.",
    linkedin: "https://www.linkedin.com/in/jeffstafford-com/",
    email: "jeff@valid.care",
    office: "Omaha, NE",
    url: "/team/jeff",
  },
  chase: {
    name: "Chase Francl",
    role: "COO & Provider Relations",
    title: "Chief Operating Officer",
    image: "/images/chase.png",
    bio: "20+ years in behavior health, previous founder, CEO of Mid-Plains Center.",
    linkedin: "https://www.linkedin.com/in/chase-francl-3342b2b1/",
    email: "chase@valid.care",
    office: "Central City, NE",
    url: "/team/chase",
  },
};
