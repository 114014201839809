import React from "react";
import { motion } from "framer-motion";
import PageTemplate from "./PageTemplate";
import "./Developers.css";

const Developers = () => {
  return (
    <PageTemplate
      title="Developer Resources"
      subtitle="Integrate with Login.health's secure healthcare API platform"
    >
      <motion.section
        className="api-overview"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <div className="api-card">
          <h2>API Overview</h2>
          <div className="api-features-grid">
            <div className="api-feature">
              <i className="fas fa-code"></i>
              <h3>RESTful API</h3>
              <p>Modern REST API with JSON payloads and standard HTTP methods</p>
            </div>
            <div className="api-feature">
              <i className="fas fa-shield-alt"></i>
              <h3>OAuth 2.0</h3>
              <p>Secure authentication using industry-standard OAuth 2.0 protocol</p>
            </div>
            <div className="api-feature">
              <i className="fas fa-file-code"></i>
              <h3>OpenAPI Spec</h3>
              <p>Complete API documentation using OpenAPI/Swagger specification</p>
            </div>
            <div className="api-feature">
              <i className="fas fa-clock"></i>
              <h3>Rate Limiting</h3>
              <p>Fair usage policies with transparent rate limiting</p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="integration-guides"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        <h2>Integration Guides</h2>
        <div className="guides-grid">
          <div className="guide-item">
            <h3>Authentication</h3>
            <pre className="code-block">
              <code>
{`// Initialize the Login.health client
const client = new LoginHealth({
  clientId: 'your-client-id',
  clientSecret: 'your-client-secret'
});

// Get an access token
const token = await client.authenticate();`}
              </code>
            </pre>
            <ul>
              <li>OAuth 2.0 authentication flow</li>
              <li>Token management</li>
              <li>Scopes and permissions</li>
            </ul>
          </div>

          <div className="guide-item">
            <h3>Records API</h3>
            <pre className="code-block">
              <code>
{`// Fetch patient records
const records = await client.records.get({
  patientId: 'patient-123',
  type: 'medical-history'
});

// Update record
await client.records.update({
  recordId: 'record-456',
  data: updatedData
});`}
              </code>
            </pre>
            <ul>
              <li>CRUD operations for health records</li>
              <li>Batch processing</li>
              <li>Version control</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="sdk-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.8 }}
      >
        <h2>SDKs & Tools</h2>
        <div className="sdk-grid">
          <div className="sdk-item">
            <i className="fab fa-js"></i>
            <h3>JavaScript</h3>
            <pre className="code-block">
              <code>npm install @login-health/sdk</code>
            </pre>
          </div>
          <div className="sdk-item">
            <i className="fab fa-python"></i>
            <h3>Python</h3>
            <pre className="code-block">
              <code>pip install login-health</code>
            </pre>
          </div>
          <div className="sdk-item">
            <i className="fab fa-java"></i>
            <h3>Java</h3>
            <pre className="code-block">
              <code>maven install login-health-sdk</code>
            </pre>
          </div>
          <div className="sdk-item">
            <i className="fas fa-code"></i>
            <h3>REST API</h3>
            <pre className="code-block">
              <code>curl -X GET api.login.health/v1/...</code>
            </pre>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="api-reference"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.8 }}
      >
        <h2>API Reference</h2>
        <div className="endpoint-list">
          <div className="endpoint">
            <span className="method get">GET</span>
            <code>/v1/records/{`{recordId}`}</code>
            <p>Retrieve a specific health record</p>
          </div>
          <div className="endpoint">
            <span className="method post">POST</span>
            <code>/v1/records</code>
            <p>Create a new health record</p>
          </div>
          <div className="endpoint">
            <span className="method put">PUT</span>
            <code>/v1/records/{`{recordId}`}</code>
            <p>Update an existing health record</p>
          </div>
          <div className="endpoint">
            <span className="method delete">DELETE</span>
            <code>/v1/records/{`{recordId}`}</code>
            <p>Delete a health record</p>
          </div>
        </div>
        <div className="api-docs-link">
          <a href="https://api.login.health/docs" target="_blank" rel="noopener noreferrer">
            <i className="fas fa-book"></i>
            View Full API Documentation
          </a>
        </div>
      </motion.section>

      <motion.section
        className="developer-resources"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.1, duration: 0.8 }}
      >
        <h2>Resources</h2>
        <div className="resources-grid">
          <div className="resource-item">
            <i className="fas fa-laptop-code"></i>
            <h3>Sandbox Environment</h3>
            <p>Test your integration in our sandbox environment with sample data</p>
            <a href="#" className="resource-link">Access Sandbox</a>
          </div>
          <div className="resource-item">
            <i className="fas fa-book"></i>
            <h3>Documentation</h3>
            <p>Comprehensive guides, API reference, and best practices</p>
            <a href="#" className="resource-link">View Docs</a>
          </div>
          <div className="resource-item">
            <i className="fas fa-users"></i>
            <h3>Community</h3>
            <p>Join our developer community for support and discussions</p>
            <a href="#" className="resource-link">Join Forum</a>
          </div>
          <div className="resource-item">
            <i className="fas fa-tools"></i>
            <h3>Tools</h3>
            <p>Developer tools, testing utilities, and sample applications</p>
            <a href="#" className="resource-link">Browse Tools</a>
          </div>
        </div>
      </motion.section>
    </PageTemplate>
  );
};

export default Developers;
