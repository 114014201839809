const API_URL = process.env.REACT_APP_API_URL || "https://api.login.health";
const DATAROOM_ID =
  process.env.REACT_APP_DATAROOM_ID || "cd06e9d7-957e-459d-940f-8549cfaca309";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const auth = {
  requestCode: async (email, clientId = "dataroom") => {
    const response = await fetch(`${API_URL}/user/otp`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        username: email.toLowerCase(),
        clientId,
      }),
      credentials: "include",
    });
    return response.json();
  },

  verifyCode: async (email, code, clientId = "dataroom") => {
    const deviceId = localStorage.getItem("deviceId") || crypto.randomUUID();
    localStorage.setItem("deviceId", deviceId);

    const response = await fetch(`${API_URL}/user/otp/verify`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        username: email.toLowerCase(),
        otp: code,
        clientId,
        deviceId,
      }),
      credentials: "include",
    });
    return response.json();
  },

  logout: async () => {
    const response = await fetch(`${API_URL}/user/logout`, {
      method: "GET",
      headers,
      credentials: "include",
    });
    localStorage.clear();
    return response.json();
  },

  getUser: async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/me`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    localStorage.setItem("user", JSON.stringify(response));
    if (response.status === 401) {
      localStorage.clear();
    }

    return response.json();
  },
  getProfile: async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/me/profile`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    if (response.status === 401) {
      localStorage.clear();
      return "unathorized";
    }
    return response.json();
  },
  getApp: async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/apps/` + DATAROOM_ID, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    if (response.status === 401) {
      localStorage.clear();
      return "unathorized";
    }
    return response.json();
  },
  getDataroom: async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `https://w2tabgjrwfo2by66nan76azpoe0upycm.lambda-url.us-east-1.on.aws/dataroom`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (response.status === 401) {
      localStorage.clear();
      return "unathorized";
    }
    return response.json();
  },
};
