import React from "react";
import { useParams } from "react-router-dom";
import PageTemplate from "./PageTemplate";
import { generateVCard } from "../utils/vcard";
import "./TeamMember.css";

const teamMembers = {
  jon: {
    name: "Jon Rhoades",
    role: "CEO & Technology",
    title: "CEO & Technology",
    image: "/images/jon.png",
    bio: "20+ years in technology, previous founder, software engineer",
    linkedin: "https://www.linkedin.com/in/jon-rhoades/",
    email: "jon@valid.care",
    phone: "(402) 410-1380",
    office: "Aurora, NE",
  },
  jeff: {
    name: "Jeff Stafford",
    role: "CFO & Business Development",
    title: "Chief Financial Officer",
    image: "/images/jeff.png",
    bio: "30+ years in business leadership, former CEO - UHC NE, investment and commercial banking.",
    linkedin: "https://www.linkedin.com/in/jeffstafford-com/",
    email: "jeff@valid.care",
    office: "Omaha, NE",
  },
  chase: {
    name: "Chase Francl",
    role: "COO & Provider Relations",
    title: "Chief Operating Officer",
    image: "/images/chase.png",
    bio: "20+ years in behavior health, previous founder, CEO of Mid-Plains Center.",
    linkedin: "https://www.linkedin.com/in/chase-francl-3342b2b1/",
    email: "chase@valid.care",
    office: "Central City, NE",
  },
};

const TeamMember = () => {
  const { id } = useParams();
  const member = teamMembers[id];

  if (!member) {
    return <div>Team member not found</div>;
  }

  return (
    <PageTemplate showHeader={false} title={member.name} subtitle={member.role}>
      <div className="team-member-profile">
        <div className="profile-header">
          <div className="profile-image">
            <img src={member.image} alt={member.name} />
          </div>
          <div className="profile-info">
            <h1>{member.name}</h1>
            <h2>{member.role}</h2>
          </div>
        </div>

        <div className="profile-content">
          <div className="bio-section">
            <h3>About</h3>
            <p>{member.bio}</p>
          </div>

          <div className="contact-section">
            <h3>Contact Information</h3>
            <div className="contact-details">
              <p>
                <strong>Title:</strong> {member.title}
              </p>
              <p>
                <strong>Office:</strong> {member.office}
              </p>
            </div>
            <div className="social-links">
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
              <a href={`mailto:${member.email}`}>
                <i className="fas fa-envelope"></i> {member.email}
              </a>
              <a href={`tel:${member.phone}`}>
                <i className="fas fa-phone"></i> {member.phone}
              </a>
              <a
                href={generateVCard(member)}
                download={`${member.name.replace(" ", "_")}.vcf`}
                className="add-contact-btn"
              >
                <i className="fas fa-address-card"></i> Add to Contacts
              </a>
              {member.calendly && (
                <a
                  href={member.calendly}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="book-call-btn"
                >
                  <i className="fas fa-calendar-alt"></i> Book a Call
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default TeamMember;
