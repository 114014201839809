export const generateVCard = (member) => {
  const [firstName, ...lastName] = member.name.split(" "); // Split name
  const lastNameString = lastName.join(" ") || "";

  const vcard = [
    "BEGIN:VCARD",
    "VERSION:3.0",
    `N:${lastNameString};${firstName};;;`, // Use structured name
    `FN:${member.name}`,
    `TITLE:${member.title}`,
    `EMAIL:${member.email}`,
    `TEL:${member.phone}`,
    `ADR;TYPE=WORK:;;${member.office}`,
    `URL:${member.linkedin}`,
    `ORG:Login.health`, // ORG stays, but properly formatted
    "END:VCARD",
  ].join("\n");

  return "data:text/vcard;charset=utf-8," + encodeURIComponent(vcard);
};
