import React from "react";
import PageTemplate from "./PageTemplate";
import "./Terms.css";

const Terms = () => {
  return (
    <PageTemplate
      showHeader={false}
      title="Terms of Service"
      subtitle="Please read these terms carefully before using our services"
    >
      <div className="terms-container">
        <div className="terms-content">
          <p className="effective-date">
            <strong>Effective Date:</strong> 9/4/2024
          </p>

          <p className="terms-intro">
            This Terms of Service Agreement (the "Agreement") is entered into by
            and between <strong>Login.Health</strong>, a healthcare software
            service platform, and <strong>User</strong> (individually, "Party"
            and collectively, "Parties"). By accessing or using the services
            provided by Login.Health, you agree to be bound by this Agreement.
          </p>

          {[...Array(13)].map((_, index) => {
            const section = index + 1;
            return (
              <div key={section} className="terms-section">
                {section === 1 && (
                  <>
                    <h2>1. Definitions</h2>
                    <ul>
                      <li>
                        <strong>"User"</strong> refers to an individual or
                        entity using Login.Health's authentication and health
                        record services.
                      </li>
                      <li>
                        <strong>"Services"</strong> means the services provided
                        by Login.Health, including but not limited to secure
                        authentication and access to personal health records.
                      </li>
                      <li>
                        <strong>"Personal Health Records (PHR)"</strong> refers
                        to health information about an individual that is
                        accessible via Login.Health's platform.
                      </li>
                      <li>
                        <strong>"Access Credentials"</strong> refers to
                        usernames, passwords, tokens, or any other form of
                        security measure used to access the Services.
                      </li>
                      <li>
                        <strong>"Confidential Information"</strong> refers to
                        any non-public information, including health records and
                        any data protected under the Health Insurance
                        Portability and Accountability Act (HIPAA).
                      </li>
                      <li>
                        <strong>"Group"</strong> refers to any organization or
                        entity managing or using Login.Health on behalf of its
                        employees or patients.
                      </li>
                    </ul>
                  </>
                )}

                {section === 2 && (
                  <>
                    <h2>2. Services</h2>
                    <ul>
                      <li>
                        <strong>Authentication:</strong> Login.Health provides
                        secure single sign-on (SSO) authentication, enabling
                        users to access multiple healthcare applications using a
                        single set of credentials.
                      </li>
                      <li>
                        <strong>Personal Health Records:</strong> The platform
                        allows users to access and manage their personal health
                        information from various healthcare providers in one
                        place.
                      </li>
                    </ul>
                  </>
                )}

                {section === 3 && (
                  <>
                    <h2>3. User Responsibilities</h2>
                    <ul>
                      <li>
                        <strong>Access Credentials:</strong> Users are
                        responsible for maintaining the confidentiality of their
                        login credentials. Login.Health is not responsible for
                        any unauthorized access due to the failure to secure
                        these credentials.
                      </li>
                      <li>
                        <strong>Accurate Information:</strong> Users agree to
                        provide accurate and up-to-date information during
                        account creation and while using the platform.
                      </li>
                      <li>
                        <strong>Compliance:</strong> Users must comply with all
                        applicable laws, including HIPAA, and must not use the
                        Services for any illegal purposes.
                      </li>
                      <li>
                        <strong>Prohibited Uses:</strong> Users shall not:
                        <ul>
                          <li>
                            Attempt to gain unauthorized access to the Services.
                          </li>
                          <li>
                            Use the Services to violate the privacy of others.
                          </li>
                          <li>
                            Upload or transmit any harmful code, virus, or
                            malware through the platform.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </>
                )}

                {section === 4 && (
                  <>
                    <h2>4. Data Privacy and Security</h2>
                    <ul>
                      <li>
                        <strong>HIPAA Compliance:</strong> Login.Health complies
                        with all applicable data privacy laws, including HIPAA.
                        We employ technical, physical, and administrative
                        safeguards to protect the privacy and security of
                        Personal Health Records.
                      </li>
                      <li>
                        <strong>User Responsibility for Data:</strong> Users
                        must take necessary steps to protect their health data
                        by logging out after each session and avoiding sharing
                        their credentials with others.
                      </li>
                      <li>
                        <strong>Data Access and Control:</strong> Users retain
                        ownership of their health data. Login.Health will not
                        share or disclose health data without explicit user
                        consent, except as required by law.
                      </li>
                    </ul>
                  </>
                )}

                {section === 5 && (
                  <>
                    <h2>5. Intellectual Property</h2>
                    <ul>
                      <li>
                        All content provided by Login.Health, including but not
                        limited to software, documentation, logos, and
                        trademarks, is the intellectual property of Login.Health
                        or its licensors.
                      </li>
                      <li>
                        Users are granted a limited, non-exclusive, and
                        non-transferable right to use the platform solely for
                        their personal health management.
                      </li>
                    </ul>
                  </>
                )}

                {section === 6 && (
                  <>
                    <h2>6. Fees</h2>
                    <ul>
                      <li>
                        Login.Health may charge fees for certain premium
                        services. All fees are clearly stated on the platform.
                        Free services will remain available unless otherwise
                        notified.
                      </li>
                      <li>
                        Any fees due must be paid promptly. Failure to pay will
                        result in restricted access to the Services.
                      </li>
                    </ul>
                  </>
                )}

                {section === 7 && (
                  <>
                    <h2>7. Limitation of Liability</h2>
                    <ul>
                      <li>
                        <strong>No Medical Advice:</strong> Login.Health
                        provides tools for managing personal health records but
                        does not offer medical advice or treatment. Users should
                        consult healthcare professionals for medical guidance.
                      </li>
                      <li>
                        <strong>Liability Cap:</strong> To the extent permitted
                        by law, Login.Health's liability for any claims arising
                        from this Agreement is limited to the amount paid by the
                        user for the Services within the 12 months preceding the
                        claim.
                      </li>
                    </ul>
                  </>
                )}

                {section === 8 && (
                  <>
                    <h2>8. Termination</h2>
                    <ul>
                      <li>
                        Users may terminate this Agreement by deactivating their
                        account.
                      </li>
                      <li>
                        Login.Health may suspend or terminate access to the
                        platform if a user violates this Agreement or applicable
                        laws.
                      </li>
                      <li>
                        Upon termination, Login.Health will retain user data as
                        required by law but will discontinue access to the
                        platform unless otherwise specified by the user.
                      </li>
                    </ul>
                  </>
                )}

                {section === 9 && (
                  <>
                    <h2>9. Confidentiality</h2>
                    <ul>
                      <li>
                        Login.Health agrees to keep all user health records
                        confidential in accordance with HIPAA and applicable
                        state laws.
                      </li>
                      <li>
                        Users agree to keep confidential any non-public
                        information related to the platform's operations.
                      </li>
                    </ul>
                  </>
                )}

                {section === 10 && (
                  <>
                    <h2>10. Indemnification</h2>
                    <p>
                      Users agree to indemnify, defend, and hold Login.Health
                      harmless from any claims arising from the misuse of the
                      platform, violation of this Agreement, or infringement of
                      third-party rights.
                    </p>
                  </>
                )}

                {section === 11 && (
                  <>
                    <h2>11. Governing Law</h2>
                    <p>
                      This Agreement shall be governed by the laws of the State
                      of Nebraska, without regard to its conflict of law
                      principles.
                    </p>
                  </>
                )}

                {section === 12 && (
                  <>
                    <h2>12. Changes to the Agreement</h2>
                    <p>
                      Login.Health reserves the right to modify these Terms of
                      Service at any time. Any changes will be effective
                      immediately upon notice, which may be provided through the
                      platform or via email. Continued use of the Services
                      constitutes acceptance of the revised Agreement.
                    </p>
                  </>
                )}

                {section === 13 && (
                  <>
                    <h2>13. Contact Information</h2>
                    <p>
                      If you have any questions or concerns regarding these
                      terms, please contact us at:
                    </p>
                    <div className="contact-info">
                      <p>
                        <strong>Login.Health</strong>
                        <br />
                        Email:{" "}
                        <a href="mailto:support@valid.care">
                          support@valid.care
                        </a>
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}

          <p className="effective-date-bottom">
            <strong>Effective Date:</strong> 9/4/2024
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Terms;
