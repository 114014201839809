import ReactGA from "react-ga4";

// Initialize GA with your measurement ID
export const initGA = () => {
  ReactGA.initialize("G-JSP2G4QWD8"); // Replace with your GA4 measurement ID
};

// Track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Track custom events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
