import React from "react";
import Menu from "./Menu";
import "./PageTemplate.css";

const PageTemplate = ({ title, subtitle, children, showHeader = true }) => {
  return (
    <div className="page-template">
      <Menu />
      {showHeader && (
        <>
          {/* Header Section */}
          <section className="page-header">
            <div className="header-content">
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </div>

            <div className="animated-background">
              {[...Array(10)].map((_, i) => (
                <i
                  key={i}
                  className={`fas fa-${
                    [
                      "heartbeat",
                      "prescription",
                      "stethoscope",
                      "pills",
                      "hospital",
                    ][i % 5]
                  } medical-symbol floating-icon`}
                  style={{
                    top: `${Math.random() * 100}%`,
                    left: `${Math.random() * 100}%`,
                    fontSize: `${Math.random() * 2 + 1}rem`,
                    animationDelay: `${Math.random() * 2}s`,
                  }}
                />
              ))}
            </div>
          </section>
        </>
      )}
      {/* Main Content */}
      <main className="page-content">{children}</main>

      {/* Footer */}
      <footer className="page-footer">
        <div className="footer-content">
          <div className="footer-sections">
            <div className="footer-links">
              <a href="/about">About</a>

              <a href="/contact">Contact</a>
              <a href="/pricing">Pricing</a>
              <a href="/providers">Providers</a>
              <a href="/features">Features</a>
              {/* <a href="/developers">Developers</a> */}
            </div>
            <div className="footer-links">
              <a href="/privacy">Privacy</a>
              <a href="/terms">Terms</a>
            </div>
            <div className="footer-contact">
              <div>
                <i className="fas fa-home"></i> &nbsp; VALID DOT CARE, Inc
              </div>

              <div>
                <i className="fas fa-envelope"></i> &nbsp; support@valid.care
              </div>
              <div>
                <i className="fas fa-map-marker-alt"></i> &nbsp; Aurora, NE
                68818
              </div>
            </div>
          </div>
          {/* <div className="footer-social">
            <a
              href="https://twitter.com/loginhealth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter fa-sm"></i>
            </a>
            <a
              href="https://linkedin.com/company/loginhealth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin fa-sm"></i>
            </a>
            <a href="mailto:contact@login.health">
              <i className="fas fa-envelope fa-sm"></i>
            </a>
          </div> */}
          <div className="footer-bottom">
            <p>&copy; 2024 Login.health</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PageTemplate;
