import React, { useEffect } from 'react';
import './StoryPath.css';

const StoryPath = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const characters = document.querySelectorAll('.story-character');
      
      characters.forEach((character) => {
        const element = character;
        const speed = element.dataset.speed || 0.5;
        const offset = element.dataset.offset || 0;
        const direction = element.dataset.direction || 'left';
        
        const xPos = direction === 'left' 
          ? -100 + (scrolled * speed)
          : 100 - (scrolled * speed);
          
        element.style.transform = `translateX(${xPos}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="story-path-container">
      <div 
        className="story-character character-connect"
        data-speed="0.3"
        data-direction="left"
      >
        {/* Placeholder for your Lunacy character */}
        <div className="character-placeholder connect"></div>
        <p>Getting Connected</p>
      </div>

      <div 
        className="story-character character-doctor"
        data-speed="0.4"
        data-direction="right"
        data-offset="200"
      >
        <div className="character-placeholder doctor"></div>
        <p>Sharing with Doctors</p>
      </div>

      <div 
        className="story-character character-family"
        data-speed="0.3"
        data-direction="left"
        data-offset="400"
      >
        <div className="character-placeholder family"></div>
        <p>Connecting with Family</p>
      </div>

      <div 
        className="story-character character-circle"
        data-speed="0.5"
        data-direction="right"
        data-offset="600"
      >
        <div className="character-placeholder circle"></div>
        <p>Complete Care Circle</p>
      </div>
    </div>
  );
};

export default StoryPath;
