import React from "react";
import { motion } from "framer-motion";
import { trackEvent } from "../services/analytics";
import PageTemplate from "./PageTemplate";
import "./Pricing.css";

const Pricing = () => {
  return (
    <PageTemplate
      title="Simple, Free Pricing"
      subtitle="Your health data should be accessible without barriers"
    >
      <motion.section
        className="pricing-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <div className="pricing-card">
          <h2>Always Free for Patients</h2>
          <div className="price">$0</div>
          <p className="forever">Forever</p>
          <ul className="features-list">
            <li>
              <i className="fas fa-check"></i>
              Unlimited health record storage
            </li>
            <li>
              <i className="fas fa-check"></i>
              Secure provider sharing
            </li>
            <li>
              <i className="fas fa-check"></i>
              Family health tracking
            </li>
            <li>
              <i className="fas fa-check"></i>
              24/7 access on all devices
            </li>
            <li>
              <i className="fas fa-check"></i>
              Advanced security features
            </li>
          </ul>
          <button
            onClick={() => {
              trackEvent('Pricing', 'Click', 'Get Started Now');
              window.location.href = "/waitlist";
            }}
            className="start-button"
          >
            Get Started Now
          </button>
        </div>
      </motion.section>

      <motion.section
        className="pricing-explanation"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        <h2>Why Free?</h2>
        <div className="explanation-grid">
          <div className="explanation-item">
            <i className="fas fa-heart"></i>
            <h3>Your Health Comes First</h3>
            <p>
              We believe access to your health information is a fundamental
              right, not a privilege. That's why Login.health will always be
              free for patients.
            </p>
          </div>

          <div className="explanation-item">
            <i className="fas fa-lock"></i>
            <h3>No Hidden Costs</h3>
            <p>
              Unlike traditional health record systems, we don't charge for
              storage, sharing, or accessing your information. Your health data
              should be freely available to you.
            </p>
          </div>

          <div className="explanation-item">
            <i className="fas fa-users"></i>
            <h3>Community Focused</h3>
            <p>
              Our mission is to empower patients by giving them control over
              their health data. A free platform ensures everyone has access to
              this essential service.
            </p>
          </div>

          <div className="explanation-item">
            <i className="fas fa-infinity"></i>
            <h3>Sustainable Model</h3>
            <p>
              We maintain our free service through partnerships with healthcare
              providers while ensuring your data privacy and control always
              remains in your hands.
            </p>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="pricing-faq"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.8 }}
      >
        <h2>Common Questions</h2>
        <div className="faq-grid">
          <div className="faq-item">
            <h3>Will it always be free?</h3>
            <p>
              Yes! Login.health will always be free for patients. This is a core
              promise of our platform.
            </p>
          </div>

          <div className="faq-item">
            <h3>Are there storage limits?</h3>
            <p>
              No. Store all your health records, images, and documents without
              worrying about space limitations.
            </p>
          </div>

          <div className="faq-item">
            <h3>How do you make money?</h3>
            <p>
              We partner with healthcare providers who pay for enhanced features
              while keeping the platform free for patients.
            </p>
          </div>

          <div className="faq-item">
            <h3>What about data privacy?</h3>
            <p>
              Your privacy is paramount. We never sell your data and maintain
              the highest security standards.
            </p>
          </div>
        </div>
      </motion.section>
    </PageTemplate>
  );
};

export default Pricing;
