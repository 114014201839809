import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { trackEvent } from "../services/analytics";
import PageTemplate from "./PageTemplate";
import "./BusinessCard.css";
import { teamMembers } from "../services/members";

const BusinessCard = () => {
  const { id } = useParams();
  const member = teamMembers[id];
  const currentUrl = window.location.href;

  if (!member) {
    return <div>Team member not found</div>;
  }

  return (
    <PageTemplate
      showHeader={false}
      title="Digital Business Card"
      subtitle={member.name}
    >
      <div className="business-card-container">
        <div className="business-card">
          <div className="card-header">
            <div className="card-image">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="card-info">
              <h1>{member.name}</h1>
              <h2>{member.title}</h2>
              <p className="company">Login.health</p>
            </div>
          </div>

          <div className="card-content">
            <div className="contact-info">
              {member.phone && (
                <a
                  href={`tel:${member.phone}`}
                  className="contact-item"
                  onClick={() =>
                    trackEvent("Contact", "Phone Click", member.name)
                  }
                >
                  <i className="fas fa-phone"></i>
                  <span>{member.phone}</span>
                </a>
              )}
              <a
                href={`mailto:${member.email}`}
                className="contact-item"
                onClick={() =>
                  trackEvent("Contact", "Email Click", member.name)
                }
              >
                <i className="fas fa-envelope"></i>
                <span>{member.email}</span>
              </a>
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="contact-item"
              >
                <i className="fab fa-linkedin"></i>
                <span>LinkedIn Profile</span>
              </a>
              {member.calendly && (
                <a
                  href={member.calendly}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-item"
                >
                  <i className="fas fa-calendar-alt"></i>
                  <span>Schedule a Meeting</span>
                </a>
              )}
            </div>

            <div className="qr-section">
              <QRCodeSVG
                value={currentUrl.replace("/code/", "/team/")}
                size={200}
              />
              <p>Scan to save contact</p>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default BusinessCard;
