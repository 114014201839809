import React, { useState } from "react";
import { motion } from "framer-motion";
import { trackEvent } from "../services/analytics";
import PageTemplate from "./PageTemplate";
import "./SignUp.css";

const SignUp = () => {
  const [formData, setFormData] = useState({
    practiceName: "",
    npi: "",
    email: "",
    phone: "",
    address: "",
    agreeToTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    trackEvent('Provider', 'Submit', 'Sign Up Form');
    try {
      const response = await fetch(
        "https://api.login.health/website/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            reason:
              "PROVIDER SIGN UP\n" +
              "\nPRACTICE NAME: " +
              formData.practiceName +
              "\nNPI: " +
              formData.npi +
              "\nEMAIL: " +
              formData.email +
              "\nPHONE: " +
              formData.phone +
              "\nADDRESS: " +
              formData.address +
              "\nAGREE TO TERMS: " +
              formData.agreeToTerms,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to send to Slack");

      alert("Thank you for joining! We will be in touch soon.");
      setFormData({
        practiceName: "",
        npi: "",
        email: "",
        phone: "",
        address: "",
        agreeToTerms: false,
      });
    } catch (error) {
      console.error("Error submitting to form:", error);
      alert(
        "There was an error submitting your request. Please try again later."
      );
    }
  };
  return (
    <PageTemplate
      title="Provider Sign Up"
      subtitle="Join our network of healthcare providers and streamline your practice"
    >
      <motion.section
        className="signup-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <div className="signup-container">
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="form-group">
              <label htmlFor="practiceName">Practice Name</label>
              <input
                type="text"
                id="practiceName"
                name="practiceName"
                value={formData.practiceName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="npi">NPI Number</label>
              <input
                type="text"
                id="npi"
                name="npi"
                value={formData.npi}
                onChange={handleChange}
                required
                pattern="[0-9]{10}"
                title="Please enter a valid 10-digit NPI number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Business Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Business Phone </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="123-456-7890"
              />
            </div>

            <div className="form-group">
              <label htmlFor="address">Practice Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group checkbox">
              <input
                type="checkbox"
                id="agreeToTerms"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
                required
              />
              <label htmlFor="agreeToTerms">
                I agree to the <a href="/terms">Terms of Service</a> and{" "}
                <a href="/privacy">Privacy Policy</a>
              </label>
            </div>

            <button type="submit" className="signup-button">
              Create Account
            </button>

            <div className="login-link">
              Already have an account? <a href="/login">Log in</a>
            </div>
          </form>

          <div className="signup-features">
            <h3>Provider Benefits</h3>
            <ul>
              <li>
                <i className="fas fa-check-circle"></i>
                Streamlined Patient Intake
              </li>
              <li>
                <i className="fas fa-sync"></i>
                EMR Integration
              </li>
              <li>
                <i className="fas fa-chart-line"></i>
                Practice Analytics
              </li>
              <li>
                <i className="fas fa-users"></i>
                Patient Portal Access
              </li>
              <li>
                <i className="fas fa-file-medical"></i>
                Digital Record Management
              </li>
              <li>
                <i className="fas fa-shield-alt"></i>
                HIPAA Compliant Platform
              </li>
              <li>
                <i className="fas fa-headset"></i>
                Dedicated Support Team
              </li>
            </ul>
          </div>
        </div>
      </motion.section>
    </PageTemplate>
  );
};

export default SignUp;
