import React, { createContext, useContext, useState } from "react";
import { auth } from "../services/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isAuth());
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const requestCode = async (email) => {
    setIsLoading(true);
    try {
      const response = await auth.requestCode(email);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  async function isAuth() {
    let profile = auth.getProfile();

    if (profile) {
      return true;
    } else {
      return false;
    }
  }

  const verifyCode = async (email, code) => {
    setIsLoading(true);
    try {
      const response = await auth.verifyCode(email, code);
      if (response.accessToken) {
        console.log(response.accessToken);
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("userId", response.id);
        setIsAuthenticated(true);
        console.log("isAuthenticated: ", isAuthenticated);
        const user = await auth.getUser();
        setUser(user);
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await auth.logout();
    } finally {
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        requestCode,
        verifyCode,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
