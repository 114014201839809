import React from "react";
import { motion } from "framer-motion";
import { trackEvent } from "../services/analytics";
import PageTemplate from "./PageTemplate";
import "./Providers.css";

const Providers = () => {
  return (
    <PageTemplate
      title="Provider Solutions"
      subtitle="Streamline your practice with advanced healthcare integration"
    >
      <motion.section
        className="provider-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <div className="provider-card">
          <h2>Advanced Integration Features</h2>
          <div className="features-grid">
            <div className="feature-item">
              <i className="fas fa-clipboard-check"></i>
              <h3>Smart Intake</h3>
              <p>
                Automated patient intake with pre-visit form completion and
                validation
              </p>
            </div>
            <div className="feature-item">
              <i className="fas fa-file-medical"></i>
              <h3>Record Requests</h3>
              <p>
                Streamlined record request management with automated fulfillment
              </p>
            </div>
            <div className="feature-item">
              <i className="fas fa-sync"></i>
              <h3>EMR Integration</h3>
              <p>Seamless integration with major EMR systems</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-chart-line"></i>
              <h3>Analytics Dashboard</h3>
              <p>Comprehensive practice analytics and patient insights</p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="workflow-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        <h2>Streamlined Workflows</h2>
        <div className="workflow-grid">
          <div className="workflow-item">
            <i className="fas fa-user-check"></i>
            <h3>Digital Patient Intake</h3>
            <ul>
              <li>Customizable intake forms</li>
              <li>Insurance verification</li>
              <li>Automated eligibility checks</li>
              <li>Digital signature collection</li>
              <li>Real-time form validation</li>
            </ul>
          </div>

          <div className="workflow-item">
            <i className="fas fa-exchange-alt"></i>
            <h3>Record Management</h3>
            <ul>
              <li>One-click record requests</li>
              <li>Automated record delivery</li>
              <li>Secure patient-to-provider sharing</li>
              <li>Bulk record requests</li>
              <li>Audit trail tracking</li>
            </ul>
          </div>

          <div className="workflow-item">
            <i className="fas fa-calendar-check"></i>
            <h3>Appointment Management</h3>
            <ul>
              <li>Smart scheduling system</li>
              <li>Automated reminders</li>
              <li>Wait-time management</li>
              <li>No-show prevention</li>
              <li>Resource optimization</li>
            </ul>
          </div>

          <div className="workflow-item">
            <i className="fas fa-chart-bar"></i>
            <h3>After the Visit</h3>
            <ul>
              <li>Patient health metrics</li>
              <li>Follow your patients home</li>
              <li>Full patient care profile</li>
              <li>Patient messaging</li>
              <li>Custom reporting</li>
            </ul>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="integration-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.8 }}
      >
        <h2>EMR Integration</h2>
        <div className="integration-content">
          <div className="integration-info">
            <h3>Seamless Connection</h3>
            <p>Connect with major EMR systems including:</p>
            <ul>
              <li>Epic</li>
              <li>Cerner</li>
              <li>Allscripts</li>
              <li>athenahealth</li>
              <li>eClinicalWorks</li>
            </ul>
            <button
              onClick={() => {
                trackEvent("Provider", "Click", "Schedule Integration Demo");
                window.location.href = "/contact";
              }}
              className="integration-button"
            >
              Schedule Integration Demo
            </button>
          </div>
          <div className="integration-features">
            <div className="integration-feature">
              <i className="fas fa-sync"></i>
              <h4>Bi-directional Sync</h4>
              <p>Real-time data synchronization between systems</p>
            </div>
            <div className="integration-feature">
              <i className="fas fa-shield-alt"></i>
              <h4>HIPAA Compliant</h4>
              <p>Secure, compliant data transfer and storage</p>
            </div>
            <div className="integration-feature">
              <i className="fas fa-code"></i>
              <h4>API Access</h4>
              <p>Custom integration capabilities via REST API</p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="pricing-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.8 }}
      >
        <h2>Pricing</h2>
        <div className="pricing-tiers">
          <div className="pricing-tier">
            <h3>Records</h3>
            <div className="price">
              $2<span>/patient</span>
            </div>
            <ul>
              <li>Developer API</li>
              <li>Record requests</li>
              <li>Email support</li>
              <li>Branded Identity provider</li>
            </ul>
            <button
              onClick={() => {
                trackEvent("Provider", "Click", "Start Free Trial - Intake");
                window.location.href = "/signup";
              }}
              className="tier-button"
            >
              Start Free Trial
            </button>
          </div>

          <div className="pricing-tier featured">
            <div className="featured-label">Plus</div>
            <h3>Workbench</h3>
            <div className="price">
              $599<span>/month</span>
            </div>
            <ul>
              <li>Advanced analytics</li>
              <li>Patient messaging</li>
              <li>Health goals</li>
              <li>Custom branding</li>
            </ul>
            <button
              onClick={() => {
                trackEvent("Provider", "Click", "Start Free Trial - Plus");
                window.location.href = "/signup";
              }}
              className="tier-button"
            >
              Start Free Trial
            </button>
          </div>

          <div className="pricing-tier">
            <h3>Enterprise</h3>
            <div className="price">Custom</div>
            <ul>
              <li>Workbench</li>
              <li>EMR/EHR integration</li>
              <li>Patient workflows</li>
              <li>Custom automation</li>
              <li>Dedicated support</li>
              <li>Bulk records requests</li>
            </ul>
            <button
              onClick={() => {
                trackEvent("Provider", "Click", "Contact Sales - Enterprise");
                window.location.href = "/signup";
              }}
              className="tier-button"
            >
              Contact Sales
            </button>
          </div>
        </div>
      </motion.section>
    </PageTemplate>
  );
};

export default Providers;
